// extracted by mini-css-extract-plugin
export var customText = "s_q5 d_dv d_cs d_cg";
export var videoIframeStyle = "s_pT d_d5 d_w d_H d_by d_b1 d_R";
export var customImageWrapper = "s_q6 d_cs d_cg d_Z";
export var customRow = "s_qc d_bD d_bf";
export var quoteWrapper = "s_gQ d_bC d_bP d_cs d_cg";
export var quoteBar = "s_pY d_H";
export var masonryImageWrapper = "s_p4";
export var title = "s_q7";
export var Title3Small = "s_q8 J_q8 J_tW J_t3";
export var Title3Normal = "s_q9 J_q9 J_tW J_t4";
export var Title3Large = "s_rb J_rb J_tW J_t5";