// extracted by mini-css-extract-plugin
export var carouselContainer = "R_wS d_w d_H d_bf d_Z";
export var carouselContainerCards = "R_wT R_wS d_w d_H d_bf d_Z";
export var carouselContainerSides = "R_wV d_w d_H d_Z";
export var prevCarouselItem = "R_wW d_w d_H d_0 d_k";
export var prevCarouselItemL = "R_wX R_wW d_w d_H d_0 d_k";
export var moveInFromLeft = "R_wY";
export var prevCarouselItemR = "R_wZ R_wW d_w d_H d_0 d_k";
export var moveInFromRight = "R_w0";
export var selectedCarouselItem = "R_w1 d_w d_H d_Z d_bf";
export var selectedCarouselItemL = "R_w2 R_w1 d_w d_H d_Z d_bf";
export var selectedCarouselItemR = "R_w3 R_w1 d_w d_H d_Z d_bf";
export var nextCarouselItem = "R_w4 d_w d_H d_0 d_k";
export var nextCarouselItemL = "R_w5 R_w4 d_w d_H d_0 d_k";
export var nextCarouselItemR = "R_w6 R_w4 d_w d_H d_0 d_k";
export var arrowContainer = "R_w7 d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainer = "R_w8 R_w7 d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainerHidden = "R_w9 R_w8 R_w7 d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var nextArrowContainer = "R_xb R_w7 d_H d_0 d_dB d_bl d_4 d_b1";
export var carouselContainerProducts = "R_xc";
export var nextArrowContainerHidden = "R_xd R_xb R_w7 d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var arrow = "R_kG d_0";
export var prevArrow = "R_xf R_kG d_0";
export var nextArrow = "R_xg R_kG d_0";
export var carouselIndicatorContainer = "R_xh d_w d_dw d_bl d_bz d_bD d_bP d_bJ d_0";
export var btnsContainer = "R_xj d_w d_bz d_bF";
export var carouselText = "R_xk d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var carouselTextInactive = "R_xl R_xk d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var indicator = "R_xm d_b7";
export var carouselIndicator = "R_xn R_xm d_b7";
export var carouselIndicatorSelected = "R_xp R_xm d_b7";
export var arrowsContainerTopRight = "R_xq d_0 d_bl d_bC";
export var arrowsContainerBottomLeft = "R_xr d_0 d_bl d_bC";
export var arrowsContainerSides = "R_xs d_0 d_bl d_bC";
export var smallArrowsBase = "R_xt d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainer = "R_xv R_xt d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrev = "R_xw R_xv R_xt d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerSmall = "R_xx R_xt d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrevSmall = "R_xy R_xx R_xt d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var icon = "R_qb";
export var iconSmall = "R_xz";
export var multipleWrapper = "R_xB d_bC d_bF d_bf";
export var multipleImage = "R_xC d_bC";
export var sidesPrevContainer = "R_xD R_xv R_xt d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";
export var sidesNextContainer = "R_xF R_xv R_xt d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";