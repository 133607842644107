// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "x_rX d_gv d_cs";
export var heroHeaderCenter = "x_gw d_gw d_cs d_dw";
export var heroHeaderRight = "x_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "x_rY d_gr d_cw";
export var heroParagraphCenter = "x_gs d_gs d_cw d_dw";
export var heroParagraphRight = "x_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "x_rZ d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "x_r0 d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "x_r1 d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "x_r2 d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "x_r3 d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "x_r4 J_r4";
export var heroExceptionNormal = "x_r5 J_r5";
export var heroExceptionLarge = "x_r6 J_r6";
export var Title1Small = "x_r7 J_r7 J_tW J_tX";
export var Title1Normal = "x_r8 J_r8 J_tW J_tY";
export var Title1Large = "x_r9 J_r9 J_tW J_tZ";
export var BodySmall = "x_rT J_rT J_tW J_vd";
export var BodyNormal = "x_rV J_rV J_tW J_vf";
export var BodyLarge = "x_rW J_rW J_tW J_vg";