// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "K_vX d_bD";
export var storyRowWrapper = "K_hx d_hx d_bK";
export var storyLeftWrapper = "K_vY d_bz d_bP";
export var storyWrapperFull = "K_vZ d_cD";
export var storyWrapperFullLeft = "K_pl d_cD d_bz d_bP d_bD";
export var contentWrapper = "K_mv d_hy";
export var storyLeftWrapperCenter = "K_v0 d_hz d_bz d_bD d_bP d_bJ";
export var storyRightWrapperCenter = "K_v1 d_hF";
export var storyHeader = "K_v2 d_hD d_w d_cs";
export var storyHeaderCenter = "K_hC d_hC d_w d_cs d_dw d_by";
export var storyParagraphCenter = "K_hB d_hB d_by d_dw";
export var storyBtnWrapper = "K_v3 d_d2 d_d1 d_w d_bz d_bG";
export var storyBtnWrapperCenter = "K_hJ d_hJ d_d1 d_w d_bz d_bD";
export var imageWrapper = "K_v4 d_fg d_Z";
export var imageWrapperFull = "K_v5 d_w d_H d_bf d_Z";
export var SubtitleSmall = "K_qf J_qf J_tW J_t6";
export var SubtitleNormal = "K_qg J_qg J_tW J_t7";
export var SubtitleLarge = "K_qh J_qh J_tW J_t8";
export var textLeft = "K_dv";
export var textCenter = "K_dw";
export var textRight = "K_dx";