// extracted by mini-css-extract-plugin
export var articleWrapper = "n_pC";
export var searchResult = "n_qj n_pC";
export var line = "n_fk d_fk d_cY";
export var articleTitle = "n_qk d_cq";
export var articleText = "n_pD d_cs";
export var authorImage = "n_ln d_ln d_b7 d_Z d_ct";
export var authorText = "n_p3 d_ch d_ct";
export var textCenter = "n_dw d_dw";
export var searchWrapper = "n_ql d_bz d_bP d_dr d_cl d_Z";
export var searchInput = "n_qm d_cF d_w d_b4";
export var searchBtn = "n_qn d_b1 d_bC d_bP d_0 d_cD d_dB";
export var text = "n_qd d_cr";
export var elementWrapper = "n_my d_cl";
export var select = "n_qp d_w d_b4";
export var articleImageWrapper = "n_pV d_cs d_Z d_V";
export var articleImageWrapperIcon = "n_pW d_cs d_Z d_V";
export var articleImageWrapperColumns = "n_qq d_cs d_fh d_Z";
export var contentPadding = "n_qr d_cS";
export var otherPadding = "n_qs d_cM";
export var paginationWrapper = "n_qt";
export var pagination = "n_qv";
export var breakMe = "n_qw";
export var activePage = "n_qx";
export var next = "n_qy";
export var pages = "n_qz";
export var searchTitle = "n_qB d_cr";
export var categoryTitle = "n_qC d_cr d_Z";
export var searchText = "n_qD d_ct";
export var bold = "n_qF";
export var field = "n_qG";
export var dateTag = "n_pM d_l d_cr d_dq";
export var icon1 = "n_pN d_cP";
export var icon2 = "n_pP d_cP d_cJ";
export var tagBtn = "n_pQ d_b1 d_cD d_dp d_dB";
export var linksWrapper = "n_p6 d_ch d_dw";
export var header = "n_pF d_w d_Z";
export var headerInner = "n_qH";
export var headerImage = "n_qJ d_bd d_0 d_8 d_7 d_4 d_9";
export var separator = "n_p2 d_cY";
export var resultImage = "n_qK";