// extracted by mini-css-extract-plugin
export var alignLeft = "w_qL d_bG";
export var alignCenter = "w_bP d_bD";
export var alignRight = "w_qM d_bH";
export var textAlignLeft = "w_rK";
export var textAlignCenter = "w_rL";
export var textAlignRight = "w_rM";
export var embedInnerWrapperDesign1 = "w_rN d_bM";
export var embed = "w_rP d_b1";
export var titleMargin = "w_rQ d_cw d_dw";
export var subtitleMargin = "w_rR d_cs d_dw";
export var paragraphMargin = "w_rS d_cw d_dw";
export var SubtitleSmall = "w_qf J_qf J_tW J_t6";
export var SubtitleNormal = "w_qg J_qg J_tW J_t7";
export var SubtitleLarge = "w_qh J_qh J_tW J_t8";
export var BodySmall = "w_rT J_rT J_tW J_vd";
export var BodyNormal = "w_rV J_rV J_tW J_vf";
export var BodyLarge = "w_rW J_rW J_tW J_vg";