// extracted by mini-css-extract-plugin
export var navbarDividedLeft = "B_fQ d_fQ d_bz d_bH d_bJ";
export var navbarDividedRight = "B_fR d_fR d_bz d_bJ";
export var menuDesign6 = "B_sy d_fM d_bz d_dw d_bP d_bJ";
export var menuDesign7 = "B_sz d_fM d_bz d_dw d_bP d_bJ";
export var menuRight = "B_sB d_fM d_bz d_dw d_bP d_bJ";
export var menuLeft = "B_sC d_fM d_bz d_dw d_bP d_bJ";
export var menuCenter = "B_sD d_fN d_fM d_bz d_dw d_bP d_w d_bD d_bJ";
export var menuDivided = "B_m9 d_fN d_fM d_bz d_dw d_bP d_w d_bD";
export var menuDesign5 = "B_nl d_fP d_fM d_bz d_dw d_bP d_bJ";
export var isBurger = "B_sF";
export var navbarItem = "B_nb d_bx";
export var navbarLogoItemWrapper = "B_fY d_fY d_bC d_bP";
export var burgerToggleVisibleOpen = "B_sG d_gd d_by d_Z d_bs";
export var burgerToggleVisible = "B_sH d_gd d_by d_Z d_bs";
export var burgerToggle = "B_sJ d_gd d_by d_Z d_bs d_Z";
export var burgerToggleOpen = "B_sK d_gd d_by d_Z d_bs";
export var burgerInput = "B_sL d_f2 d_w d_H d_by d_cc d_dl d_bd d_0 d_8 d_7 d_4 d_9";
export var burgerIcon = "B_sM d_f3 d_w d_H";
export var burgerLine = "B_sN d_f1";
export var burgerMenuDesign6 = "B_sP d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuDesign7 = "B_sQ d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuLeft = "B_sR d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuDesign5 = "B_sS d_f8 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuRight = "B_sT d_f8 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuCenter = "B_sV d_f9 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuDivided = "B_sW d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var secondary = "B_sX d_bC d_bP";
export var compact = "B_sY";
export var navDivided = "B_sZ";
export var staticBurger = "B_s0";
export var menu = "B_s1";
export var navbarDividedLogo = "B_s2";
export var nav = "B_s3";
export var fixed = "B_s4";