// extracted by mini-css-extract-plugin
export var lbContainerOuter = "N_wz";
export var lbContainerInner = "N_wB";
export var movingForwards = "N_wC";
export var movingForwardsOther = "N_wD";
export var movingBackwards = "N_wF";
export var movingBackwardsOther = "N_wG";
export var lbImage = "N_wH";
export var lbOtherImage = "N_wJ";
export var prevButton = "N_wK";
export var nextButton = "N_wL";
export var closing = "N_wM";
export var appear = "N_wN";